import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// components
import { FullPageLoader } from './components/FullPageLoader';
// other
import { ROUTES } from './constants';
import { LoadingContext } from './context';
import { reassignLogging } from './utils';
import { Platform } from './types';

// lazy import pages
const Home = lazy(() => import('./pages/Home'));
const DesktopPage = lazy(() => import('./pages/DesktopPage'));

const Routing: React.FC = () => (
  <Router>
    <Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route path={ROUTES.root} element={<Navigate to={ROUTES.home} />} />
        <Route path={ROUTES.home} element={<Home />} />
      </Routes>
    </Suspense>
  </Router>
);

const MobileOnly: React.FC = () => {
  const { loading } = useContext(LoadingContext);

  reassignLogging();

  return <>{loading ? <FullPageLoader /> : <Routing />}</>;
};

export const App: React.FC = () => {
  const platform = Telegram?.WebApp.platform;
  const isMobileApp = platform === Platform.Android || platform === Platform.IOS;
  const isDevMode = process.env.REACT_APP_TG_TOKEN;
  const isMobileMode = isDevMode || isMobileApp;

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME;
    try {
      if (appName) {
        window.telegramAnalytics.init({
          token: String(process.env.REACT_APP_TELEGRAM_ANALYTICS_TOKEN),
          appName,
        });
        console.log('Telegram Analytics SDK initialized successfully');
      }
    } catch (error) {
      console.error('Error initializing Telegram Analytics SDK:', error);
    }
  }, []);

  return <Suspense fallback={<FullPageLoader />}>{isMobileMode ? <MobileOnly /> : <DesktopPage />}</Suspense>;
};
