import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, defaultSystem } from '@chakra-ui/react';
// components
import { App } from './App';
// styles
import './styles/scss/main.scss';
//other
import { LoadingProvider, ThemeProvider } from './context';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider value={defaultSystem}>
      <ThemeProvider>
        <LoadingProvider>
          <App />
        </LoadingProvider>
      </ThemeProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
