export enum StorageKey {
  AppVersion = 'appVersion',
  BackdoorID = 'backdoorID',
  BybitConnection = 'bybitConnection',
  CheckingMint = 'checkingMint',
  DiamondFilters = 'diamondFilters',
  DiamondSortOption = 'diamondSortOption',
  Geolocation = 'geolocation',
  HasMarketPlacePopupBeenShown = 'hasMarketPlacePopupBeenShown',
  RewardPopupShowLimit = 'rewardPopupShowLimit',
  Minted = 'minted',
  TelegramUserId = 'telegramUserId',
  Theme = 'theme',
  Wallet = 'wallet',
  SessionId = 'sessionId',
}
